.contact {
	background-color: #1e21336a;
	border-radius: 20px;
	width: 60%;
	height: 60%;
	box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
	z-index: 4;
	backdrop-filter: blur(1px);
	display: flex;
}

.column1 {
	width: 45%;
	height: 100%;
}

.column1-text {
	margin-top: 8%;
	margin-left: 12%;
	text-align: left;
}

.column1-text h1 {
	font-size: 28px;
}

.column2 {
	width: 55%;
	height: 100%;
}

.column2 form {
	text-align: left;
}

.contact-form {
	margin-top: 10%;
	width: 95%;
}

#form-name,
#form-email,
#form-subject,
#form-message {
	background-color: rgb(35, 41, 65);
	border-radius: 10px;
	color: #a7a7a9;
}

#form-message {
	min-height: 48px;
	max-height: 225px;
}

@media only screen and (max-width: 768px) {
	.contact {
		width: 90%;
		height: 80%;
		flex-direction: column;
	}
	.column1 {
		width: 100%;
		height: 40%;
	}
	.column1-text {
		margin-top: 5%;
		margin-left: 0;
		display: flex;
		justify-content: center;
	}
	.column1-text h1 {
		font-size: 20px;
	}
	.column2 {
		width: 100%;
		height: 60%;
		display: flex;
		justify-content: center;
	}
	.column1-graphic {
		height: 150px;
	}
	.contact-form {
		margin-top: 10%;
		width: 90%;
	}
	.formSubmitButton {
		display: flex;
		justify-content: flex-end;
	}
	#form-message {
		min-height: 48px;
		max-height: 100px;
	}
}
