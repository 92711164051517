.projects {
	width: 60%;
	height: 75%;
	max-width: 100vw;
	background-color: #1e21336a;
	border-radius: 20px;
	padding-left: 5%;
	padding-right: 5%;
	box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
	z-index: 4;
	backdrop-filter: blur(1px);
	box-sizing: border-box;
}

.slick-list {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

@media only screen and (max-width: 768px) {
	.projects {
		width: 90%;
		height: 80%;
	}
}
