.about {
	background-color: #1e21336a;
	border-radius: 20px;
	width: 60%;
	height: 60%;
	box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
	z-index: 4;
	backdrop-filter: blur(1px);
	display: flex;
}

.column {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.intro {
	text-align: center;
	padding-right: 20%;
}

.intro h1 {
	font-size: 42px;
	margin: 0.5em 0em;
}

.intro p {
	font-size: 26px;
	margin: 0;
}

.avatar_image {
	border-radius: 50%;
	border: 3px solid #4f7986;
	width: 60%;
	box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgb(0, 0, 0, 0.3);
}

.socials ul {
	padding-inline-start: 0px;
	display: inline-flex;
	list-style: none;
}

.socials ul li {
	margin-left: 10px;
	margin-right: 10px;
	font-size: 1.3em;
	transition: transform 0.2s ease;
}

.socials ul li:hover {
	margin-left: 10px;
	margin-right: 10px;
	font-size: 1.3em;
	transform: scale(1.2);
}

@media only screen and (max-width: 768px) {
	.about {
		flex-direction: column;
		width: 90%;
		height: 80%;
	}

	.column {
		width: 100%;
		height: 50%;
	}

	.intro {
		margin-bottom: 20%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-right: 0%;
		padding: 0 20px;
	}
	.intro h1 {
		font-size: 32px;
		margin: 0.5em 0em;
	}

	.intro p {
		font-size: 22px;
		margin: 0;
	}

	.avatar_image {
		border-radius: 50%;
		border: 3px solid #4f7986;
		width: 50%;
		max-width: 230px;
		max-height: 230px;
		box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.3),
			0 6px 20px 0 rgb(0, 0, 0, 0.3);
	}
}
