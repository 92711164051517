.project-card {
	border-radius: 20px;
	height: 420px;
	margin-left: 25px;
	margin-right: 25px;
	box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	background-color: #2329416a;
	transition: transform 0.5s ease;
}

.project-card:hover {
	transform: translate(0, -5px);
	box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.15);
}

.project-card-top {
	display: flex;
	justify-content: center;
	height: 35%;
}

.project-card-top img {
	border-radius: 8px;
	margin-top: 5%;
	height: 95%;
}

.project-card-bottom {
	text-align: left;
	padding: 10%;
}

.project-links ul {
	justify-content: center;
	padding-inline-start: 0px;
	display: inline-flex;
	list-style: none;
	width: 100%;
}

.project-links ul li {
	margin-left: 20%;
	margin-right: 20%;
	font-size: 1.5em;
	transition: transform 0.1s ease;
}

.project-links ul li:hover {
	margin-left: 20%;
	margin-right: 20%;
	font-size: 1.5em;
	transform: scale(1.2);
}

.project-card-skills {
	color: rgb(171, 171, 174);
}

@media only screen and (max-width: 768px) {
	.project-card-top img {
		border-radius: 8px;
		margin-top: 5%;
		width: 90%;
		height: 85%;
	}

	.project-card-bottom h1 {
		font-size: 22px;
	}
}
